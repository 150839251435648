import Swiper from 'swiper'; // eslint-disable-line import/no-extraneous-dependencies
import { Navigation, Pagination, A11y } from 'swiper/modules/index.mjs'; // eslint-disable-line import/no-extraneous-dependencies
import { debounce } from 'lodash'; // eslint-disable-line import/no-extraneous-dependencies
import { removeHiddenAssets } from './util';
import { infoTooltipModal } from './checkout/homeClubHelpers';
import { equalizeElements } from './checkout/membershipHelpers';

const SWIPER_ID = '.js-membership-slider';

const CampaignMembership = (() => {
    let mySwiper;

    const destroyParams = {
        cleanStyles: true,
    };
    /**
     * Initializes membership swiper
     */
    const handleSwiperMembership = () => {
        if (!$(SWIPER_ID).length > 0) {
            return;
        }

        removeHiddenAssets($(SWIPER_ID), '.swiper-slide');

        if (window.innerWidth >= 901) {
            if (typeof mySwiper !== 'undefined') {
                mySwiper.destroy(destroyParams);
            }
            return;
        }

        // if already initiated, stop here
        if (window.innerWidth <= 900 && $('.js-membership-slider .swiper-slide-active').length !== 0) {
            return;
        }

        if (typeof mySwiper !== 'undefined') {
            mySwiper.destroy(destroyParams);
        }

        mySwiper = undefined;

        const options = {
            // configure Swiper to use modules
            modules: [Navigation, Pagination, A11y],
            spaceBetween: 12,
            slidesPerView: 1,
            breakpointsInverse: true,
            centeredSlides: true,
            watchOverflow: true,
            pagination: {
                el: '.js-membership-slider-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.js-membership-slider-next',
                prevEl: '.js-membership-slider-prev',
            },
            a11y: {
                prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
                nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
                firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
                lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 72,
                },
            },
        };

        if (typeof mySwiper === 'undefined') {
            mySwiper = new Swiper(SWIPER_ID, options);
        }
    };

    const equalizeMembershipPrices = () => {
        // Equalize heights for prices (so they all space up evenly when extra pricing is injected)
        equalizeElements('.membership-tile__header');
        equalizeElements('.membership-tile__footer');
    };

    /**
     * Initializes the event listeners for the lead generation club page
     */
    const initializeListeners = () => {
        const $membershipButtons = $('.js-membership-btn-new');
        const $membershipToggleButtonsWrapper = $('.js-toggle-buttons');
        const $membershipToggleButtons = $('.js-membership-toggle');
        const $membershipYearToggleButton = $('.js-membership-toggle#year');
        const $membershipFlexToggleButton = $('.js-membership-toggle#flex');
        const $yearMembershipBlocks = $('.js-membership-block.year');
        const $flexMembershipBlocks = $('.js-membership-block.flex');

        infoTooltipModal('from-side');

        $membershipButtons.each((i, button) => {
            const $membershipButton = $(button);
            $membershipButton.on('click', e => {
                if ($membershipButton.hasClass('disabled')) {
                    e.preventDefault();
                    $membershipButton.removeClass('btn--secondary').addClass('disabled');
                    return;
                }

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: 'precheckout',
                    step: '1',
                    component_name: 'join',
                });
            });
        });

        $membershipToggleButtons.each((i, button) => {
            const $membershipToggleButton = $(button);
            $membershipToggleButton.on('click', () => {
                const id = $membershipToggleButton.attr('id');
                $membershipToggleButton.addClass('toggle-button--active');

                if (id === 'year') {
                    $yearMembershipBlocks.css('display', 'flex');
                    $flexMembershipBlocks.css('display', 'none');
                    $membershipFlexToggleButton.removeClass('toggle-button--active');
                    $membershipToggleButtonsWrapper.removeClass('toggle-flex--active');
                    $membershipToggleButtonsWrapper.addClass('toggle-year--active');
                } else if (id === 'flex') {
                    $yearMembershipBlocks.css('display', 'none');
                    $flexMembershipBlocks.css('display', 'flex');
                    $membershipYearToggleButton.removeClass('toggle-button--active');
                    $membershipToggleButtonsWrapper.removeClass('toggle-year--active');
                    $membershipToggleButtonsWrapper.addClass('toggle-flex--active');
                }
            });
        });
    };

    /**
     * Handle the USP's view more/view less button clicks on the membership campaign page
     */
    const handleMembershipUSP = () => {
        const $viewMore = $('.js-view-more-usp');
        const $viewLess = $('.js-view-less-usp');
        const $allUSP = $('.js-usp-list-all');

        $viewMore.on('click', () => {
            $viewMore.hide('fast');
            $viewLess.show('fast');
            $allUSP.show('fast');
        });

        $viewLess.on('click', () => {
            $viewMore.show('fast');
            $viewLess.hide('fast');
            $allUSP.hide('fast');
        });
    };

    return {
        init() {
            handleSwiperMembership();
            initializeListeners();
            handleMembershipUSP();
            equalizeMembershipPrices();
        },
        resize() {
            handleSwiperMembership();
            equalizeMembershipPrices();
        },
    };
})();

export default (() => {
    $(CampaignMembership.init);
    $(window).on('resize', debounce(CampaignMembership.resize, 300));
})();
